import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { motion } from 'framer-motion';
import { Button } from 'styles';
import { PageNotFoundIcon } from 'images/svgs';

function PageNotFound() {
  return (
    <Container>
      <InnerContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 1 }}>
        <h2>Seems like we lost this page</h2>
        <SVGContainer>
          <PageNotFoundIcon
            primaryColor="var(--primary-color)"
            secondaryColor="var(--primary-color)"
          />
        </SVGContainer>
        <div />
      </InnerContainer>
      <Overlay
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 2 }}>
        <h2>Let&apos;s get you back home</h2>
        <Button type="button" onClick={() => navigate('/')}>
          Back To Home
        </Button>
      </Overlay>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  width: 100vw;
  h2 {
    color: var(--primary-color);
    font-size: 1.75rem;
    font-weight: 300;
  }

  @media screen and (min-width: 48rem) {
    justify-content: center;
    h2 {
      font-size: 2rem;
    }
  }
`;

const InnerContainer = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  margin: 0 auto;
  padding: 9.75rem 0 3.125rem;
  width: 90%;
  @media screen and (min-width: 48rem) {
    gap: 3rem;
    padding: 12.4rem 0 12.95rem;
    width: 80%;
  }

  @media screen and (min-width: 75rem) {
    gap: 3.25rem;
    padding: 4rem 0 2rem;
    width: 60%;
  }
`;

const SVGContainer = styled.div`
  height: auto;
  width: 100%;
`;

const Overlay = styled(motion.div)`
  align-items: center;
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.6);
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 2.75rem;
  height: 55%;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;
  @media screen and (min-width: 48rem) {
    height: 47.5%;
  }
  @media screen and (min-width: 64rem) {
    height: 45%;
  }
`;

export default PageNotFound;

